// brand customizations

$theme-colors: (
  "black": #000,
  "white": #fff,
  "brand-accent": #44aa19,
  "grey-1": #5a5a5a,
  "grey-2": #a5a5a5,
  "grey-3": #d5d5d5,
  "grey-4": #eaeaea,
  "grey-5": #f2f2f2,
  "grey-6": #eeeeee,
  "warm-grey": #fafaf9,
  "excellent": #44aa19,
  "dark-grey": #363636,
  "light-grey": #d6d6d6,
  "medium-grey": #5c5c5c,
  "custom": #900,
  "good": #00ae00,
  "average": #e6b719,
  "weak": #e34d3a,
);
$enable-validation-icons: false;
// import bootstrap stylesheet

@import "~bootstrap/scss/bootstrap";

.popup-social-header {
  .modal-body {
    h5 {
      color: #676767;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      color: #363636;
      text-align: center;
    }
  }

  .modal-footer {
    .btn-dark {
      background: #5c5c5c;
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      flex: 1;
    }
  }

  .modal-content {
    width: 75%;
  }

  .modal-dialog-centered {
    justify-content: center;
  }
}

.popup-pitch {
  .modal-content {
    margin: 0.5rem;
  }

  .modal-header {
    padding: 1rem 1.2rem 0;
    margin: 0;
    align-items: start;

    .btn-close {
      width: 0.6rem;
      opacity: 1;
      font-size: 10px;
    }

    p {
      font-size: 12px;
      color: #494949;
      margin-top: 10px;
    }
  }

  .modal-title {
    div {
      img {
        filter: invert(100%) sepia(96%) saturate(16%) hue-rotate(232deg)
          brightness(103%) contrast(100%);
        width: 25px;
        height: 25px;
        background: #ffffff;
        border-radius: 50%;
        padding: 5px;
      }
    }

    h5 {
      font-size: 20px;
      font-weight: bold;
      margin-left: 10px;
      text-align: left;
    }
  }

  .modal-body {
    padding: 0;

    p {
      padding: 1rem 1.2rem 0.5rem;
      font-size: 15px;
      color: #494949;
    }
  }

  .modal-footer {
    padding: 0 1.2rem 0.75rem;
    border: none;

    button {
      padding: 5px;
    }
  }
}

.popup-terms {
  h5 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }

  ul {
    list-style: none;

    li {
      position: relative;
      font-size: 14px;
      padding-bottom: 10px;
      padding-left: 30px;

      &:last-child {
        padding-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        background-image: url(../assets/icons/circle-tick.svg);
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }
}

.popup-delete {
  .modal-footer {
    button {
      &:first-child {
        width: 45%;
      }

      &:last-child {
        width: 45%;
        background-image: linear-gradient(270deg, #f00000, #b20000) !important;
      }
    }
  }
}

.popup-more {
  .modal-content {
    margin: 0.5rem;
  }

  .modal-header {
    border: none;
    padding-bottom: 5px;

    .btn-close {
      width: 0.6rem;
      opacity: 1;
      font-size: 10px;
    }

    h6 {
      font-size: 17px;
      font-weight: bold;
    }
  }

  .modal-body {
    ul {
      list-style: none;
      padding: 0;

      li {
        .control-checkbox {
          border: 1px solid #c9c9c9;
          padding: 15px 10px;
          border-radius: 5px;
          display: block;
          position: relative;
          padding-left: 50px;
          margin-bottom: 15px;
          cursor: pointer;
          font-size: 14px;

          input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked {
              .control__indicator {
                border: 2px solid #007bff;
                background: #007bff;
              }
            }
          }

          .control__indicator {
            position: absolute;
            top: 15px;
            left: 15px;
            height: 18px;
            width: 18px;
            border-radius: 4px;
            border: 1px solid #818181;
            background: url(../assets/icons/checkbox.svg);
          }

          input:checked ~ .control__indicator:after {
            display: block;
          }

          .control__indicator:after {
            top: 65%;
            left: 50%;
            -webkit-transform: translate(-48%, -50%);
            -ms-transform: translate(-48%, -50%);
            transform: translate(-48%, -50%);
            border: none;
          }

          .control__indicator:after {
            content: url(../assets/icons/checkbox-checked.svg);
            position: absolute;
            display: none;
          }
        }
      }

      .active {
        border: 2px solid #000000 !important;
        background: #eeeeed;
      }
    }
  }

  .modal-footer {
    background: #eaeaea;
    box-shadow: 0px -2px 5px 0px rgba(228, 228, 228, 0.75);
    -webkit-box-shadow: 0px -2px 5px 0px rgba(228, 228, 228, 0.75);
    -moz-box-shadow: 0px -2px 5px 0px rgba(228, 228, 228, 0.75);

    .action-btn-new {
      padding: 5px;

      &::after {
        top: 5px;
        background: url(../assets/icons/tick.svg);
      }
    }
  }
}

.header-confirm {
  border: none;
}

.no-border {
  border: none !important;
  padding: 15px 30px 15px !important;

  .control__indicator {
    left: 0 !important;
  }
}

.control-checkbox {
  border: 2px solid #c9c9c9;
  padding: 15px 10px;
  border-radius: 5px;
  display: block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked {
      .control__indicator {
        border: 2px solid #007bff;
        background: #007bff;
      }
    }
  }

  .control__indicator {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    border: 1px solid #818181;
    background: url(../assets/icons/checkbox.svg);
  }

  input:checked ~ .control__indicator:after {
    display: block;
  }

  .control__indicator:after {
    top: 65%;
    left: 50%;
    -webkit-transform: translate(-48%, -50%);
    -ms-transform: translate(-48%, -50%);
    transform: translate(-48%, -50%);
    border: none;
  }

  .control__indicator:after {
    content: url(../assets/icons/checkbox-checked.svg);
    position: absolute;
    display: none;
  }
}

.card-group {
  label {
    padding: 15px;
    display: block;
    box-shadow: 0 5px 15px 0 rgba(49, 49, 93, 0.1),
      0 3px 5px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;

    .card-list {
      color: #000000;
      font-weight: 900;
      font-size: 16px;
    }

    span {
      text-align: right;
      margin-right: 15px;
    }

    p {
      font-size: 12px;
      color: #8898aa;
    }

    #saved-card {
      display: flex;
      align-items: center;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
      }

      span {
        font-weight: 500;
        font-size: 12px;
        background: #99d9f2;
        color: #3c58bc;
        padding: 2px 5px;
        border-radius: 5px;
        margin-right: 0;
      }
    }

    img {
      margin-right: 15px;
    }

    .text-danger {
      color: #aa1919 !important;
    }

    hr {
      background-color: #ebebeb;
      opacity: 1;
      margin-bottom: 10px;
    }
  }
}

ul.invite-popup-new {
  > li {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c9c9c9;
    padding: 15px 0;

    &:last-child {
      border-bottom: none;
    }

    &::before {
      content: none;
    }

    p {
      margin-bottom: 0;
    }

    a {
      color: #000000;
    }

    .disabled {
      color: #a5a5a5;
    }
  }
}

ul.invite-popup {
  > li {
    padding-left: 0;

    &::before {
      content: none;
    }

    > a {
      border: 2px solid #c9c9c9;
      border-radius: 5px;
      display: block;
      padding: 15px 10px;
      color: #363636;
      text-decoration: none;
      position: relative;

      &::after {
        content: url(../assets/icons/chevron-down.svg);
        position: absolute;
        right: 10px;
      }
    }

    > ul {
      padding: 10px 15px !important;
      border-left: 2px solid #c9c9c9;
      border-right: 2px solid #c9c9c9;
      border-bottom: 2px solid #c9c9c9;
      border-radius: 0 0 5px 5px;

      > li {
        padding-left: 0;

        &::before {
          content: none;
        }

        button {
          border: 2px solid #000000;
          width: 100%;
          border-radius: 20px;
          font-weight: bold;

          &:hover {
            color: #000000;
            background-color: #ffffff;
          }
        }

        > ul {
          > li {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #c9c9c9;

            &:last-child {
              border-bottom: none;
            }

            &::before {
              content: none;
            }

            > p {
              margin-bottom: 0;
            }

            > a {
              color: #000000;
            }
          }
        }
      }
    }
  }

  > .no-border-radius {
    a {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.split-pay-pop {
  margin-bottom: 0;

  li {
    border: 2px solid #c9c9c9;
    border-radius: 5px;
    padding: 15px 10px !important;
    color: #363636;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    p {
      margin-bottom: 0;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      min-width: 150px;
      max-width: max-content;
      text-overflow: ellipsis;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.radio-container {
  .radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
  }

  .radio-item input[type="radio"] {
    display: none;
  }

  .radio-item label {
    color: #666;
    font-weight: normal;
  }

  .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #3c3c3c;
    background-color: transparent;
  }

  .radio-item input[type="radio"]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #3c3c3c;
  }
}

.hover-cursor {
  &:hover {
    cursor: pointer;
  }
}

.send-invite {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.qrcode-popup {
  border: 2px solid #000;
  width: fit-content;
  margin: 0 auto;
}

.nav-tabs {
  max-width: 376px;
  margin-right: auto;
  margin-left: auto;
  overflow-x: auto;
  overflow-y: hidden;
  display: -webkit-box;
  display: -moz-box;
  flex-wrap: unset;

  .nav-item {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .nav-link {
    color: #000000;
    font-size: 17px;
    padding: 0;
    border: none;
    font-weight: 600;
    padding: 0 15px;
  }

  .nav-link.active {
    border-bottom: 5px solid #000000;
    color: #000000;
  }
}

.tab-content {
  max-width: 376px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.games-listing {
  ul {
    display: flex;
    max-width: 100%;
    overflow-x: scroll;

    li {
      padding: 5px;
      cursor: pointer;
    }
  }

  .game-slide-bx {
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    max-width: 148px;
    box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.1);

    .img {
      position: relative;

      .date {
        position: absolute;
        top: 12px;
        left: 12px;
        color: #fff;
        font-weight: 900;
        background-image: linear-gradient(270deg, #54dd1b, #327417) !important;
        border-radius: 4px;
        text-align: center;
        padding: 5px;
        font-size: 11px;
        line-height: 12px;

        span {
          display: block;
          font-size: 20px;
          padding-bottom: 2px;
        }
      }

      .right-txt {
        background: #fff;
        font-weight: bold;
        background: #fff;
        border-radius: 4px;
        text-align: center;
        padding: 5px;
        font-size: 9px;
        position: absolute;
        top: 12px;
        right: 12px;
        line-height: normal;

        &.recurring {
          background-image: linear-gradient(
            270deg,
            #215b09,
            #0c1d05
          ) !important;
          color: white;
        }
      }

      .bottom-txt {
        font-weight: bold;
        color: #fff;
        border-radius: 4px;
        text-align: center;
        padding: 2px;
        font-size: 8px;
        position: absolute;
        bottom: 12px;
        left: 12px;
        line-height: normal;

        img {
          margin-right: 5px;
        }
      }
    }

    .content {
      padding: 10px;

      h4 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
      }

      .time {
        font-size: 11px;
      }
    }
  }
}

.action-btn-empty {
  width: 100%;
  padding: 17px;
  border-radius: 27px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-image: unset;
  background-color: transparent !important;
  border: 1.5px solid #000000;
  color: #000000;
}

.popular-actions {
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.1);

  .no-outline {
    border: 0 !important;
  }

  li {
    border-bottom: 1px solid #cfcfcf;
    padding: 19px 15px;
    font-weight: bold;
    cursor: pointer;

    &:last-child {
      border-bottom: 0;
    }
  }

  .list {
    padding: 8px !important;
  }
}

.date-tile {
  display: flex;
  flex-direction: column;
  width: 36px;
  color: #fff;
  font-weight: 900;
  background-image: linear-gradient(270deg, #54dd1b, #327417) !important;
  border-radius: 4px;
  text-align: center;
  padding: 5px;
  font-size: 11px;
  line-height: 12px;

  .day {
    font-size: 20px;
    margin-bottom: 2px;
  }
}

.date-tile-bg {
  background-image: unset !important;
  color: #000000;

  span {
    font-size: 18px;
    margin-bottom: 2px;
  }
}

.place-time {
  font-size: 13px;
  font-weight: normal;
}

.game-type {
  font-size: 9px;
  display: flex;

  .recurring {
    background-image: linear-gradient(270deg, #215b09, #0c1d05) !important;
    color: #fff;
    padding: 2px 4px;
    border-radius: 4px;
    margin-right: 14px;
  }

  .one-off {
    background-image: linear-gradient(270deg, #ff8100, #ff0000) !important;
    border-color: #ff6d00 !important;
    color: #fff !important;
    padding: 2px 4px;
    border-radius: 4px;
    margin-right: 14px;
  }
}

.games-list-tabs {
  .tab-content {
    padding-right: 0;
    padding-left: 0;
  }
}

.edit-search {
  li {
    border: 1.5px solid #d5d5d5;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &::after {
      content: url(../assets/icons/right-chevron-black.svg);
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 15px;
    }

    ul {
      margin-top: 20px;

      li {
        border: none;
        padding: 0;
        font-weight: normal;

        &::after {
          content: unset;
        }
      }
    }
  }
}

.selection {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    label.active {
      border: 2px solid #000000;
      background: #eeeeed;
    }
  }

  .rad-label {
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 0;
    cursor: pointer;
    transition: 0.3s;
    border: 2px solid #c9c9c9;
    border-radius: 5px;
    padding: 15px;
  }

  .rad-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;
  }

  .rad-design {
    width: 22px;
    height: 22px;
    border-radius: 100px;
    background: url(../assets/icons/radio-checked.svg);
    position: relative;
  }

  .rad-design::before {
    content: "";
    display: inline-block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: url(../assets/icons/radio.svg);
    transform: scale(1);
    transition: 0.3s;
  }

  .rad-input:checked + .rad-design::before {
    transform: scale(0);
  }

  .rad-text {
    margin-left: 14px;
    font-size: 14px;
    transition: 0.3s;
  }
}

.block-timeslot {
  .tab-content {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.disabled {
  .control-checkbox {
    background: #e9e9e9;
    color: #888888;

    .control__indicator {
      background: url(../assets/icons/checkbox-disabled.png);
    }
  }
}

.btn-gradient {
  background: linear-gradient(87deg, #ff8100, #ff0000) !important;
  color: #fff !important;
  transition: all ease 0.25s !important;
  border-color: #ff6d00 !important;
}

.font-color-gradient {
  color: #ff6d00 !important;
}

.width-80px {
  width: 80px;
}
.width-120px {
  width: 120px;
}

.fs-14 {
  font-size: 14px;
}

.fs-13 {
  font-size: 13px;
}

.list-style-circle {
  list-style-type: disc !important;
  padding-left: 2rem !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-13 {
  font-size: 13px;
}

.list-style-circle {
  list-style-type: disc !important;
  padding-left: 2rem !important;
}

ul.player-popup {
  margin-top: 10px;

  li {
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    padding: 15px 10px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      &:first-child {
        font-weight: bold;
      }

      &:last-child {
        font-size: 13px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
.load-more {
  background: linear-gradient(87deg, #ff8100, #ff0000);
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
}
