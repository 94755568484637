.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'SFProText';
  src: url(./fonts/SFProText-Black.woff2) format('woff2'),
      url(./fonts/SFProText-Black.woff) format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'SFProText';
  src: url(./fonts/SFProText-Bold.woff2) format('woff2'),
      url(./fonts/SFProText-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url(./fonts/SFProText-Light.woff2) format('woff2'),
      url(./fonts/SFProText-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url(./fonts/SFProText-Medium.woff2) format('woff2'),
      url(./fonts/SFProText-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url(./fonts/SFProText-Semibold.woff2) format('woff2'),
      url(./fonts/SFProText-Semibold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url(./fonts/SFProText-Regular.woff2) format('woff2'),
      url(./fonts/SFProText-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url(./fonts/SFProText-Thin.woff2) format('woff2'),
      url(./fonts/SFProText-Thin.woff) format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
